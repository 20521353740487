import 'airbnb-js-shims';
import 'unfetch/polyfill';
import LogRocket from 'logrocket';
// eslint-disable-next-line
import React from 'react';
import ReactDOM from 'react-dom';
import { Redirect, Router, Route, Switch } from 'react-router';
import { Provider } from 'react-redux';
import ReactGA from 'react-ga';
import classnames from 'classnames';
// we call this up here because others set GA values
import { name, version } from '../package.json';
import browserHistory from './lib/history.js';
import AppAttendant from './app-attendant.js';
import cookies from './lib/encrypted-cookies';
import RootAttendant from './routes/root-attendant.js';
import SignIn from './routes/sign-in.js';
import SignOut from './routes/sign-out.js';
import CompanyInvoices from './routes/company-invoices.js';
import OnboardingUser from './routes/onboarding-user.js';
import OnboardingCompany from './routes/onboarding-company.js';
import OnboardingTeam from './routes/onboarding-team.js';
import OnboardingDeliveryInfo from './routes/onboarding-delivery-info.js';
import OnboardingBillingInfo from './routes/onboarding-billing-info.js';
import FourOFour from './routes/example-404.js';
import configureStore from './stores/index.js';
import ForgotPassword from './routes/forgotpassword.js';
import ResetPassword from './routes/resetpassword.js';
import SettingsUserAttendant from './routes/settings-user-attendant.js';
import SettingsCompanyAttendant from './routes/settings-company-attendant.js';
import SettingsTeamAttendant from './routes/settings-team-attendant.js';
import OfficeAttendantSchedule from './routes/office-attendant-schedule.js';
import OfficeAttendantCleaningDetails from './routes/office-attendant-cleaning-details.js';
import OfficeAttendantCleaningDetail from './routes/office-attendant-cleaning-detail.js';
import OfficeAttendantCleaningReports from './routes/office-attendant-cleaning-reports.js';
import OfficeAttendantCleaningReport from './routes/office-attendant-cleaning-report.js';
import SuppliesSupplyLevels from './routes/supplies-supply-levels.js';
import SuppliesSupplyLevelsBudgetCodes from './routes/supply-levels-budget-codes.js';
import SuppliesOrders from './routes/supplies-orders.js';
import SuppliesOrder from './routes/supplies-order.js';
import SuppliesOrderBudgetCode from './routes/supplies-order-budget-codes.js';
import OfficeLuvCatalog from './routes/officeluv-catalog.js';
import ProductCategory from './routes/product-category.js';
import ProductCategorySubset from './routes/product-category-subset.js';
import Checkout from './routes/checkout.js';
import ContactUsRoute from './routes/contact-us.js';
import CatalogSearch from './routes/catalog-search.js';
import SuppliesOrderEdit from './routes/supplies-order-edit.js';
import SettingsUserNotifications from './routes/settings-user-notifications.js';
import SettingsEmployeeDeliveryPreference from './routes/settings-employee-delivery-preference';
import StaffClockedOut from './routes/staff-clocked-out.js';
import InsightsBudgets from './routes/insights-budgets.js';
import InsightsBudgetCode from './routes/insights-budget-code.js';
import OrdersScheduled from './routes/orders-scheduled.js';
import PromotedBrands from './routes/promoted-brands.js';
import ExternalVendorInvoiceNew from './routes/external-vendor-invoice-new.js';
import InsightsExternalInvoices from './routes/insights-external-invoices.js';
import InsightsExternalInvoice from './routes/insights-external-invoice.js';
import InsightsVendor from './routes/insights-vendor.js';
import OrdersScheduledUnified from './routes/orders-scheduled-unified.js';
import NavbarSide from './components/nav/navbar-side.js';
import CalendarView from './routes/calendar-view.js';
import OrdersScheduledUnifiedApproval from './routes/orders-scheduled-unified-approval.js';
import CustomerRoles from './routes/customer-roles.js';
import CustomerRoleNew from './routes/customer-role-new.js';
import CustomerRoleEdit from './routes/customer-role-edit.js';
import EmployeeShow from './routes/employee-show.js';
import CheckoutConfirm from './routes/checkout-confirm.js';
import EmployeeStipends from './routes/employee-stipends.js';
import {
  ANALYTICS_ID,
  AUTH_COOKIE,
  CLIENT,
  LOGROCKET_ENABLED,
} from './strings.js';
import './index.css';
import './print.css';
import ZendeskChatObserver from './components/zendesk-chat-observer.js';

// eslint-disable-next-line
console.log(`%c███ You are running ${CLIENT}`, 'color:#00a98c');
ReactGA.initialize(ANALYTICS_ID, { debug: window.ODEBUG });
ReactGA.set({ dimension4: `${name}:${version}` });
const store = configureStore();
const authCookie = Object.assign({}, cookies.getJSON(AUTH_COOKIE));
authCookie &&
  authCookie.hmac &&
  ReactGA.set({ userId: authCookie.hmac.username });
if (LOGROCKET_ENABLED) {
  LogRocket.init('awafoz/officeluv-dash', {
    release: version,
  });
  authCookie && authCookie.hmac && LogRocket.identify(authCookie.hmac.username);
}

class App extends React.Component {
  componentDidUpdate() {
    this.logPageView();
  }

  logPageView = () => {
    if (window.location.pathname.match('/catalog-search')) {
      const page = window.location.pathname + window.location.search;
      ReactGA.set({ page: page });
      ReactGA.pageview(page);
    } else {
      ReactGA.set({ page: window.location.pathname });
      ReactGA.pageview(window.location.pathname);
    }
  };

  render() {
    return (
      <RootAttendant location={this.props.location}>
        <Switch>
          <Route exact path="/" component={AppAttendant} />
          <Route
            path="/office-attendant/schedule"
            component={OfficeAttendantSchedule}
          />
          <Route
            path="/office-attendant/shift-details/:id"
            component={OfficeAttendantCleaningDetail}
          />
          <Route
            path="/office-attendant/shift-details"
            component={OfficeAttendantCleaningDetails}
          />
          <Route
            path="/office-attendant/shift-reports/:id"
            component={OfficeAttendantCleaningReport}
          />
          <Route
            path="/office-attendant/shift-reports"
            component={OfficeAttendantCleaningReports}
          />
          <Route path="/contact-us" component={ContactUsRoute} />
          <Route path="/sign-in" component={SignIn} />
          <Route path="/sign-out" component={SignOut} />
          <Route path="/password/forgot" component={ForgotPassword} />
          <Route path="/settings/user" component={SettingsUserAttendant} />
          <Route
            path="/settings/company"
            component={SettingsCompanyAttendant}
          />
          <Route path="/settings/team/:id" component={EmployeeShow} />
          <Route path="/settings/team" component={SettingsTeamAttendant} />
          <Route
            path="/settings/notifications"
            component={SettingsUserNotifications}
          />
          <Route
            path="/settings/delivery-preferences"
            component={SettingsEmployeeDeliveryPreference}
          />
          <Route path="/settings/stipends" component={EmployeeStipends} />
          <Route path="/password/edit" component={ResetPassword} />
          <Route path="/invitation/accept" component={OnboardingUser} />
          <Route path="/onboarding/company" component={OnboardingCompany} />
          <Route path="/onboarding/team" component={OnboardingTeam} />
          <Route
            path="/onboarding/delivery-info"
            component={OnboardingDeliveryInfo}
          />
          <Route
            path="/onboarding/billing-info"
            component={OnboardingBillingInfo}
          />
          <Route path="/company/invoices" component={CompanyInvoices} />
          <Route
            path="/supplies/levels/:id/budget-codes"
            component={SuppliesSupplyLevelsBudgetCodes}
          />
          <Route
            exact
            path="/supplies/levels"
            component={SuppliesSupplyLevels}
          />
          <Route
            path="/supplies/orders/:id/budget-codes"
            component={SuppliesOrderBudgetCode}
          />
          <Route
            path="/supplies/orders/:id/edit"
            component={SuppliesOrderEdit}
          />
          <Route path="/supplies/orders/:id" component={SuppliesOrder} />
          <Route path="/supplies/orders" component={SuppliesOrders} />
          <Route path="/supplies/catalog-search" component={CatalogSearch} />
          <Route
            path="/supplies/catalog/:id/:subset"
            component={ProductCategorySubset}
          />
          <Route path="/supplies/catalog/:id" component={ProductCategory} />
          <Route path="/supplies/catalog" component={OfficeLuvCatalog} />
          <Route
            path="/supplies/checkout-confirmation"
            component={CheckoutConfirm}
          />
          <Route path="/supplies/checkout" component={Checkout} />
          <Route path="/staff-clocked-out" component={StaffClockedOut} />
          <Route path="/insights/budgets" component={InsightsBudgets} />
          <Route
            path="/insights/vendor-invoices/new"
            component={ExternalVendorInvoiceNew}
          />
          <Route
            path="/insights/vendor-invoices/:id"
            component={InsightsExternalInvoice}
          />
          <Route
            path="/insights/vendor-invoices"
            component={InsightsExternalInvoices}
          />
          <Route
            path="/insights/budget-codes/:id"
            component={InsightsBudgetCode}
          />
          <Route path="/insights/vendors/:name" component={InsightsVendor} />
          <Route
            path="/orders/scheduled/:id/approve"
            component={OrdersScheduledUnifiedApproval}
          />
          <Route
            path="/orders/scheduled/:id/edit"
            component={OrdersScheduledUnified}
          />
          <Route
            path="/orders/scheduled/new"
            component={OrdersScheduledUnified}
          />
          <Route
            path="/orders/scheduled/:id"
            component={OrdersScheduledUnifiedApproval}
          />
          <Route path="/orders/scheduled" component={OrdersScheduled} />
          <Route path="/calendar" component={CalendarView} />
          <Route path="/customer-roles/new" component={CustomerRoleNew} />
          <Route path="/customer-roles/:id" component={CustomerRoleEdit} />
          <Route path="/customer-roles" component={CustomerRoles} />
          <Route path="/featured-products" component={PromotedBrands} />
          <Redirect from="/featured-brands" to="/featured-products" />
          <Route
            path="/faq"
            component={() => {
              window.location.replace('https://officeluv.zendesk.com/hc');
              return null;
            }}
          />
          <Route path="*" component={FourOFour} />
        </Switch>
        <ZendeskChatObserver />
      </RootAttendant>
    );
  }
}

ReactDOM.render(
  <Provider store={store}>
    <div id="outer-container" style={{ height: '100%' }}>
      <Router history={browserHistory}>
        <Route path="*" component={() => <NavbarSide />} />
        <main
          id="page-wrap"
          className={classnames('', {
            'no-nav':
              !authCookie ||
              !authCookie.hmac ||
              window.location.pathname.match(/onboarding/),
          })}>
          <Route component={App} />
        </main>
      </Router>
    </div>
  </Provider>,
  document.getElementById('root')
);
