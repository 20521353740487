import React from 'react';
import { Header, Segment } from 'semantic-ui-react';
import BasicLocationForm from '../basic-location-form.js';
import './settings-company-attendant.css';

export class SettingsCompanyAttendant extends React.PureComponent {
  componentDidMount() {
    window.totango.track('Viewed Office Details.', 'Settings');
  }

  render() {
    return (
      <div className="card">
        <Header as="h2" attached="top">
          Office Details
        </Header>
        <Segment attached="bottom" className="flex-wrapper">
          <BasicLocationForm
            submitText="Update Company Details"
            customClass="settings-company-attendant"
            textOnly={true}
          />
        </Segment>
      </div>
    );
  }
}

SettingsCompanyAttendant.propTypes = {};

export default SettingsCompanyAttendant;
