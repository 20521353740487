import React from 'react';
import { useEffect, useState } from 'react';

import './zendesk-chat-observer.css';

const ZendeskChatObserver = ({ onOpen, onClose }) => {
  const [isChatWindowOpen, setIsChatWindowOpen] = useState(false);

  useEffect(() => {
    // Initialize the chat observer
    const initializeChatObserver = async () => {
      // Wait for zE object to be available
      while (!window.zE) {
        await new Promise((resolve) => setTimeout(resolve, 100));
      }

      // Set initial state
      setIsChatWindowOpen(window.zE('webWidget:get', 'chat:isChatting'));

      // Listen for chat window events
      window.zE('webWidget:on', 'open', () => {
        setIsChatWindowOpen(true);
        console.log('Chat window opened');
      });

      window.zE('webWidget:on', 'close', () => {
        setIsChatWindowOpen(false);
        console.log('Chat window closed');
      });
    };

    initializeChatObserver();

    // Cleanup event listeners
    // return () => {
    //   window.zE('webWidget:off', 'open');
    //   window.zE('webWidget:off', 'close');
    // };
  }, [onOpen, onClose]);

  if (isChatWindowOpen) {
    return (
      <a className="contact-button" href="https://app.officeluv.com/contact-us">
        Contact Us
      </a>
    );
  } else {
    return null;
  }
};

export default ZendeskChatObserver;
