import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Form, Message } from 'semantic-ui-react';
import {
  submitCreateLocation,
  submitUpdateLocation,
} from '../actions/locations-actions.js';
import { US_STATES_OPTIONS } from '../strings.js';
import 'semantic-ui-css/components/form.min.css';
import 'semantic-ui-css/components/message.min.css';
import './basic-location-form.css';

let requiredFields = [
  'name',
  'address',
  'city',
  'state',
  'zip',
  'number_of_employees',
  'floors',
];

export class BasicLocationForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = props.isNewLocation ? {} : this.constructState(props);
  }

  constructState = (props) => {
    return {
      name: props.location.name,
      address: props.location.address,
      address2: props.location.address2,
      city: props.location.city,
      zip: props.location.zip,
      state: props.location.state,
      number_of_employees: props.location.number_of_employees,
      floors: props.location.floors,
    };
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.isNewLocation) return;

    const next = nextProps.location;
    this.setState(() => {
      return {
        name: next.name,
        address: next.address,
        address2: next.address2,
        city: next.city,
        zip: next.zip,
        state: next.state,
        number_of_employees: next.number_of_employees,
        floors: next.floors,
      };
    });
  }

  setStateAttribute(attr, prev, next) {
    return next[attr];
    // if ( !!prev && !next ) return null;
    // return next[attr] || prev[attr];
  }

  handleChangeField = (e, { value, name }) => {
    let newState = {};
    this.setState(() => {
      newState[name] = value;
      newState[`error_${name}`] = null;
      return newState;
    });
  };

  handleChangeFormattedField = (formatter) => {
    return (e, { value, name }) => {
      let newState = {};
      this.setState(() => {
        newState[name] = formatter(value);
        newState[`error_${name}`] = null;
        return newState;
      });
    };
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.validate();
    if (this.isDisabled()) return;

    let data;
    if (this.props.isNewLocation) {
      data = {
        ...this.commonSubmitData(),
        customer_id: this.props.customer.id,
        account_manager_id: this.props.location.account_manager_id,
      };
      this.props.actions
        .submitCreateLocation(data, { bubble: !!this.props.onSubmitSuccess })
        .then((result) => {
          this.props.onSubmitSuccess(result);
        });
    } else {
      data = {
        ...this.commonSubmitData(),
        id: this.props.location.id,
      };
      this.props.actions
        .submitUpdateLocation(data, { bubble: !!this.props.onSubmitSuccess })
        .then((result) => {
          this.props.onSubmitSuccess(result);
        });
    }
    this.props.onSubmit(data);
  };

  commonSubmitData = () => {
    return {
      name: this.state.name,
      address: this.state.address,
      address2: this.state.address2,
      city: this.state.city,
      zip: this.state.zip,
      state: this.state.state,
      number_of_employees: this.state.number_of_employees,
      floors: this.state.floors,
      _request: Math.random().toString(),
    };
  };

  isDisabled = () => {
    return requiredFields.reduce((acc, val) => {
      if (this.state[val]) return acc;
      return true;
    }, false);
  };

  hasErrors = () => {
    return (
      Object.keys(this.state)
        .filter((k) => k.match(/^error_/))
        .filter((k) => {
          return this.state[k];
        }).length > 0
    );
  };

  validate = () => {
    this.setState((prev) => {
      return requiredFields.reduce((acc, val) => {
        if (prev[val]) return acc;
        acc[`error_${val}`] = 'Required';
        return acc;
      }, {});
    });
  };

  formatZip = (val) => {
    return (val.match(/\d*-*\d*/) || [''])[0];
  };

  formatNumber = (val) => {
    return (val.match(/\d*\.?\d*/) || [''])[0];
  };

  render() {
    if (this.props.textOnly) {
      return (
        <div style={{ fontSize: '1.4rem' }}>
          <p style={{ fontSize: '1.4rem' }}>
            <b>Office Name: </b>
            {this.state.name}
          </p>
          <p style={{ fontSize: '1.4rem' }}>
            <b>Address: </b>
            {this.state.address}
          </p>
          <p style={{ fontSize: '1.4rem' }}>
            <b>Suite/Apt: </b>
            {this.state.address2}
          </p>
          <p style={{ fontSize: '1.4rem' }}>
            <b>City: </b>
            {this.state.city}
          </p>
          <p style={{ fontSize: '1.4rem' }}>
            <b>State: </b>
            {this.state.state}
          </p>
          <p style={{ fontSize: '1.4rem' }}>
            <b>Postal code: </b>
            {this.state.zip}
          </p>
          <p style={{ fontSize: '1.4rem' }}>
            <b>Number of Employees: </b>
            {this.state.number_of_employees}
          </p>
          <p style={{ fontSize: '1.4rem' }}>
            <b>Number of Floors: </b>
            {this.state.floors}
          </p>

          <hr />
          <span>
            Has your office location moved? Please reach out to
            support@officeluv.com to update your account address.
          </span>
        </div>
      );
    } else {
      return (
        <Form
          loading={this.props.isRequesting}
          onSubmit={this.handleSubmit}
          error={this.hasErrors()}
          className={this.props.customClass}>
          <Form.Input
            fluid
            autoFocus
            error={this.state.error_name}
            name="name"
            label="Office Name"
            placeholder="Office name"
            value={this.state.name || ''}
            onChange={this.handleChangeField}
          />
          <Form.Group>
            <Form.Input
              width={12}
              error={this.state.error_address}
              name="address"
              label="Address"
              placeholder="Your address"
              value={this.state.address || ''}
              onChange={this.handleChangeField}
            />
            <Form.Input
              width={4}
              name="address2"
              label="Suite/Apt"
              placeholder="Suite/apt"
              value={this.state.address2 || ''}
              onChange={this.handleChangeField}
            />
          </Form.Group>
          <Form.Group>
            <Form.Input
              width={8}
              error={this.state.error_city}
              name="city"
              label="City"
              placeholder="Your city"
              value={this.state.city || ''}
              onChange={this.handleChangeField}
            />
            <Form.Select
              width={4}
              error={this.state.error_state}
              name="state"
              label="State"
              placeholder={'State'}
              value={this.state.state || ''}
              options={US_STATES_OPTIONS}
              onChange={this.handleChangeField}
            />
            <Form.Input
              width={4}
              error={this.state.error_zip}
              name="zip"
              label="Postal code"
              value={this.state.zip || ''}
              placeholder={'Code'}
              onChange={this.handleChangeFormattedField(this.formatZip)}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Input
              fluid
              error={this.state.error_number_of_employees}
              name="number_of_employees"
              label="Number of Employees"
              placeholder="Number of office employees"
              value={this.state.number_of_employees || ''}
              onChange={this.handleChangeFormattedField(this.formatNumber)}
            />
            <Form.Input
              fluid
              error={this.state.error_floors}
              name="floors"
              label="Number of Floors"
              placeholder="Number of office floors"
              value={this.state.floors || ''}
              onChange={this.handleChangeFormattedField(this.formatNumber)}
            />
          </Form.Group>
          <Message error>
            <Message.Header>Error</Message.Header>
            <Message.List>
              {this.hasErrors() && (
                <Message.Item>Please fill out required fields</Message.Item>
              )}
            </Message.List>
          </Message>
          <Form.Group
            style={{
              marginTop: '2rem',
            }}>
            <Form.Button width={8} fluid primary>
              {this.props.submitText}
            </Form.Button>
            {this.props.children}
          </Form.Group>
        </Form>
      );
    }
  }
}

BasicLocationForm.propTypes = {
  actions: PropTypes.shape({
    submitUpdateLocation: PropTypes.func.isRequired,
  }).isRequired,
  customClass: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  onSubmitSuccess: PropTypes.func,
  customer: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string,
  }).isRequired,
  isNewLocation: PropTypes.bool.isRequired,
  location: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string,
    address: PropTypes.string,
    address2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zip: PropTypes.string,
    number_of_employees: PropTypes.number,
    floors: PropTypes.number,
    latitude: PropTypes.number,
    longitude: PropTypes.number,
  }).isRequired,
  isRequesting: PropTypes.bool.isRequired,
  submitText: PropTypes.string.isRequired,
};

BasicLocationForm.defaultProps = {
  submitText: 'Next',
  onSubmit: function () {},
  onSubmitSuccess: function () {},
  customClass: '',
  isNewLocation: false,
};

function mapStateToProps(state) {
  return {
    location: state.locations.open,
    customer: state.customers.open,
    isRequesting:
      state.locations.requesting.length > 0 ||
      state.customers.isRequesting ||
      state.employees.isRequesting,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        submitCreateLocation,
        submitUpdateLocation,
      },
      dispatch
    ),
  };
}

function areStatesEqual(prev, next) {
  return (
    prev.locations.open === next.locations.open &&
    prev.customers.open === next.customers.open &&
    prev.locations.requesting === next.locations.requesting &&
    prev.customers.isRequesting === next.customers.isRequesting &&
    prev.employees.isRequesting === next.employees.isRequesting
  );
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(BasicLocationForm);
